/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import { PortableText } from "@portabletext/react";
import PortableTextComponent from "../components/serializers/portableTextComponents.js";
import "../scss/page/home.scss";
import "../scss/page/products.scss";
import "../scss/page/contact.scss";
import "../scss/page/furnished.scss";
import "../scss/page/lease-paperwork.scss";

const PageDefinition = ({ data }) => {
  const node = data.page;
  const metaTitle = node.metaTitle ? node.metaTitle : node.title;
  const metaDescription = node.metaDescription ? node.metaDescription : "";
  console.log(node);
  return (
    <div id={node.slug.current}>
      <SEO title={metaTitle} lang="en" description={metaDescription}></SEO>
      <Layout type="page-outer">
        {node._rawEntities && (
          <div
            sx={{
              "#property-list": {
                ul: {
                  padding: "80px 0px",
                  display: "flex",
                  flexWrap: "wrap",
                  li: {
                    width: [
                      "100%",
                      "calc(100% / 2 - 25px)",
                      "calc(100% / 3 - 25px)",
                    ],
                    marginRight: ["0px", "25px", "25px"],
                    marginBottom: "20px",
                    display: "flex",
                    "&:nth-child(3n)": {
                      marginRight: ["0px", "25px", "0px"],
                    },
                    "&:nth-child(2n)": {
                      marginRight: ["0px", "0px", "25px"],
                    },
                  },
                },
              },
            }}
            className="entities-container"
          >
            <PortableText
              value={node._rawEntities}
              components={PortableTextComponent}
            />
          </div>
        )}
      </Layout>
    </div>
  );
};
export default PageDefinition;

export const pageDefinitionQuery = graphql`
  query PageDefinitionBySlug($slug: String!) {
    page: sanityPageDefinition(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      metaTitle
      metaDescription
      title
      _rawEntities(resolveReferences: { maxDepth: 15 })
    }
  }
`;
